import {PQContractType} from "~/widgets/pq/types/PQTypes";
import {TemplateCodesEnum} from "~/widgets/pq/types/TemplateCodes.enum";
import {AllVersionFormatter} from "~/widgets/pq/services/contractTextFormatters/AllVersionFormatter";
import {FirstVersionFormatter} from "~/widgets/pq/services/contractTextFormatters/FirstVersionFormatter";
import {PQFormattingService} from "~/widgets/pq/services/Formating/PQFormattingService";
import {IPQBuilder} from "~/widgets/pq/PQBuilderInterface";
import {SecondVersionFormatter} from "~/widgets/pq/services/contractTextFormatters/SecondVersionFormatter";

export class Fruit2Builder implements IPQBuilder {
    build(contractObject: PQContractType | Record<string, never>, contractTemplate: TemplateCodesEnum, tender: any): Record<string, any>[] {
        return [
            AllVersionFormatter.createTitle(contractObject as PQContractType),
            FirstVersionFormatter.createBasicHeader(contractObject as PQContractType),
            AllVersionFormatter.createContractText(contractTemplate, contractObject, tender),
            PQFormattingService.getLocationTitle(contractTemplate, contractObject),
            FirstVersionFormatter.createSignature(contractObject),
            SecondVersionFormatter.createGenericAddition1(contractObject),
            SecondVersionFormatter.createGenericAddition2(contractObject, tender),
        ];
    }
}
