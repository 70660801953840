import {PdfItemEnum, TextConfigType} from "~/widgets/pq/types/TextConfigType";
import {pqGenericBase, pqGenericTexts} from "~/widgets/pq/templates/generic/configs/pqGenericTexts";
import {DEFAULT_TEXT_FIELDS, STRING} from "~/constants/string";
import {pqBase} from "~/widgets/pq/configs/pqTexts";
import {FormattingFunctionsEnum} from "~/widgets/pq/services/Formating/config/FormattingFunctions.enum";
import {medicineV2textConfig} from "~/widgets/pq/templates/medicine2/config/medicineV2textConfig";
import * as PDF_HELPER_CONST from "~/constants/pdf/pdfHelperConstants";

export const medicineV2ContractConfig: TextConfigType = {
    subject: [
        {
            text: [medicineV2textConfig.subject.obligationsToBuyer, pqGenericTexts.subject.productClassificationID, 0],
            paths: ["items[0].classification.id"],
            defaults: [DEFAULT_TEXT_FIELDS.UNDERSCORES_16],
            pdfType: PdfItemEnum.LIST_ITEM,
        },
        medicineV2textConfig.subject.ownership,
        medicineV2textConfig.subject.terminology,
        medicineV2textConfig.subject.securement,
        medicineV2textConfig.subject.permitDocuments,
        {
            text: [pqGenericTexts.subject.tender, 0, STRING.DELIMITER.DOT_NEW_LINE, pqGenericTexts.subject.note],
            paths: ["tender_id"],
            defaults: [DEFAULT_TEXT_FIELDS.UNDERSCORES_40],
            pdfType: PdfItemEnum.LIST_ITEM,
        },
    ],
    assortment: [
        medicineV2textConfig.assortment.unitName,
        medicineV2textConfig.assortment.volumesReduction,
    ],
    guarantee: [
        medicineV2textConfig.guarantee.supplierGuaranties,
        medicineV2textConfig.guarantee.bestBefore,
        medicineV2textConfig.guarantee.imposibilityOfReplacement,
        medicineV2textConfig.guarantee.prohibitionOnCirculation,
        medicineV2textConfig.guarantee.sideEffects,
        {
            header: [pqBase.customer, pqBase.supplier],
            text: [pqBase.phone, pqBase.phone, pqBase.email, pqBase.email],
            paths: ["buyer.signerInfo.telephone", "suppliers[0].signerInfo.telephone", "buyer.signerInfo.email", "suppliers[0].signerInfo.email"],
            defaults: [DEFAULT_TEXT_FIELDS.UNDERSCORES_40, DEFAULT_TEXT_FIELDS.UNDERSCORES_40],
            widths: [PDF_HELPER_CONST.ROW_ALL_WIDTH, PDF_HELPER_CONST.ROW_ALL_WIDTH],
            pdfType: PdfItemEnum.TABLE,
        },
    ],
    price: [
        {
            text: [pqGenericTexts.price.amountDefinition, 0],
            paths: ["value.amount"],
            defaults: [DEFAULT_TEXT_FIELDS.DEFAULT_PRICE],
            pdfType: PdfItemEnum.LIST_ITEM,
            functionName: [FormattingFunctionsEnum.PRICE_WITH_TAX_TO_TEXT],
        },
        medicineV2textConfig.price.unitPrice,
        medicineV2textConfig.price.allSupplierCosts,
    ],
    paymentOrder: [
        medicineV2textConfig.paymentOrder.settlements,
        {
            text: [pqGenericTexts.paymentOrder.buyerPays, 0],
            paths: ["items"],
            defaults: [DEFAULT_TEXT_FIELDS.UNDERSCORES_16],
            pdfType: PdfItemEnum.LIST_ITEM,
            functionName: [FormattingFunctionsEnum.PAYMENT_DETAILS],
        },
        medicineV2textConfig.paymentOrder.paymentDate,
        medicineV2textConfig.paymentOrder.budgetCustomer,
        medicineV2textConfig.paymentOrder.prepayment,
        medicineV2textConfig.paymentOrder.notCommercialCredit,
    ],
    package: [
        medicineV2textConfig.package.properContainer,
        medicineV2textConfig.package.transportPacking,
        medicineV2textConfig.package.noMarking,
        medicineV2textConfig.package.noReturn,
        medicineV2textConfig.package.packingTools,
    ],
    deliveryTerms: [
        medicineV2textConfig.deliveryTerms.deliveryBasis,
        medicineV2textConfig.deliveryTerms.expenseOfSupplier,
        medicineV2textConfig.deliveryTerms.beforeTransfer,
        medicineV2textConfig.deliveryTerms.shippingParts,
        medicineV2textConfig.deliveryTerms.arrivalTime,
        medicineV2textConfig.deliveryTerms.accordingToInstruction,
        medicineV2textConfig.deliveryTerms.deliveryAddress,
        medicineV2textConfig.deliveryTerms.thirdPartyChecker,
        medicineV2textConfig.deliveryTerms.oneCalenderDay,
        medicineV2textConfig.deliveryTerms.receivingCheckups,
        medicineV2textConfig.deliveryTerms.entryControl,
        medicineV2textConfig.deliveryTerms.consideredTransferred,
        medicineV2textConfig.deliveryTerms.requirementDeviation,
        medicineV2textConfig.deliveryTerms.exceedingProducts,
        medicineV2textConfig.deliveryTerms.requirementDeviation2,
        medicineV2textConfig.deliveryTerms.vidhilenna,
        medicineV2textConfig.deliveryTerms.deviationAct,
        medicineV2textConfig.deliveryTerms.deviationActContent,
        medicineV2textConfig.deliveryTerms.deliveryDeadline,
        medicineV2textConfig.deliveryTerms.declineReceiving,
        medicineV2textConfig.deliveryTerms.substandard,
        medicineV2textConfig.deliveryTerms.accountable,
    ],
    responsibilities: [
        medicineV2textConfig.responsibilities.currentLegislation,
        medicineV2textConfig.responsibilities.deliveryViolation,
        medicineV2textConfig.responsibilities.refuseToAccept,
        medicineV2textConfig.responsibilities.writtenRequest,
        medicineV2textConfig.responsibilities.substandardDelivery,
        medicineV2textConfig.responsibilities.applicationOfFines,
        medicineV2textConfig.responsibilities.taxCredit,
    ],
    forceMajeure: [
        medicineV2textConfig.forceMajeure.onset,
        medicineV2textConfig.forceMajeure.martialLaw,
        medicineV2textConfig.forceMajeure.martialLawExcluded,
        medicineV2textConfig.forceMajeure.postponed,
        medicineV2textConfig.forceMajeure.suffered,
        medicineV2textConfig.forceMajeure.immediateNotification,
        medicineV2textConfig.forceMajeure.noClaims,
        medicineV2textConfig.forceMajeure.impossible,
        medicineV2textConfig.forceMajeure.noDismissalReason,
        medicineV2textConfig.forceMajeure.warAware,
    ],
    disputes: [
        medicineV2textConfig.disputes.parley,
        medicineV2textConfig.disputes.court,
        medicineV2textConfig.disputes.claims,
    ],
    anticorruption: [
        medicineV2textConfig.anticorruption.thirdPartyImpact,
        medicineV2textConfig.anticorruption.notifyOnImpact,
        medicineV2textConfig.anticorruption.incomeLegalization,
        medicineV2textConfig.anticorruption.confidentiality,
        medicineV2textConfig.anticorruption.confidentialityGuarantees,
        medicineV2textConfig.anticorruption.civilCodex,
    ],
    sanction: [
        medicineV2textConfig.sanctions.unilateralRefusalProvider,
        medicineV2textConfig.sanctions.unilateralRefusalProducer,
        medicineV2textConfig.sanctions.sanctionedCountries,
        medicineV2textConfig.sanctions.legalEntity,
    ],
    contractAction: [
        {
            text: [pqGenericTexts.contractAction.agreementValidTermStart, 0, pqGenericTexts.contractAction.agreementValidTermEnd],
            paths: ["period.endDate"],
            defaults: [pqBase.year],
            pdfType: PdfItemEnum.LIST_ITEM,
            functionName: [FormattingFunctionsEnum.CONVERT_DATE],
        },
        medicineV2textConfig.contractAction.periodExtension,
        medicineV2textConfig.contractAction.startOfYear,
    ],
    messaging: [
        medicineV2textConfig.messaging.noDifferFromOffer,
        medicineV2textConfig.messaging.noChangesAfterSign,
        medicineV2textConfig.messaging.mutualConsent,
        medicineV2textConfig.messaging.interestedInChangesSide,
        medicineV2textConfig.messaging.changesInBase,
        medicineV2textConfig.messaging.earlyTermination,
        medicineV2textConfig.messaging.noReleaseOfLiability,
        medicineV2textConfig.messaging.reconciliation,
        medicineV2textConfig.messaging.modifiedRulesApply,
    ],
    confidential: [
        medicineV2textConfig.confidential.definition,
        medicineV2textConfig.confidential.thirdParty,
        medicineV2textConfig.confidential.notifyOnConfidentiality,
        medicineV2textConfig.confidential.exerciseOfRights,
    ],
    otherTerms: [
        medicineV2textConfig.otherTerms.currentLegislation,
        medicineV2textConfig.otherTerms.interpretation,
        medicineV2textConfig.otherTerms.guarantee,
        {
            text: [pqGenericTexts.otherTerms.messages, STRING.DELIMITER.NEW_LINE, pqGenericBase.fromSupplier, 0, STRING.DELIMITER.NEW_LINE, pqGenericBase.fromBuyer, 1, STRING.DELIMITER.NEW_LINE, pqGenericTexts.otherTerms.messagesDetailsInAgreement],
            paths: ["suppliers[0].signerInfo.email", "buyer.signerInfo.email"],
            defaults: [DEFAULT_TEXT_FIELDS.UNDERSCORES_40, DEFAULT_TEXT_FIELDS.UNDERSCORES_40],
            pdfType: PdfItemEnum.LIST_ITEM,
        },
        medicineV2textConfig.otherTerms.fax,
        medicineV2textConfig.otherTerms.originalCopies,
        medicineV2textConfig.otherTerms.noThirdParty,
        medicineV2textConfig.otherTerms.additionals,
    ],
};
