export enum TemplateCodesEnum {
    FRUIT = "03220000-9.0001.01",
    FRUIT2 = "03220000-9.0002.01",
    GAS = "09130000-9.0001.01",
    GAS2 = "09130000-9.0002.01",
    PHARM = "33600000-6.0001.01",
    MEDICINE = "33190000-8.0001.01",
    COMPUTER = "30210000-4.0001.01",
    SOFTWARE = "30210000-4.0002.01",
    OTHER = "00000000-0.0001.01",
    GENERIC = "00000000-0.0002.01",
    FOOD = "15000000-9.0001.01",
    MEDICINE2 = "33600000-6.0002.01",
    PHARM2 = "33190000-8.0002.01"
}
